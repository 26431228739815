<template>
  <b-overlay :show="showOverlay">
    <div v-if="pageData">
      <b-card>
        <language-picker
            @getLanguage="setLanguage"
        ></language-picker>
      </b-card>
      <b-card>
        <b-row>
          <b-col class="mb-1" cols="12">
            <h2>Edit About us</h2>
          </b-col>

          <b-col cols="12" md="12">
            <ckEditorMain ref="editorContent" @getEditorContent="setEditorContent" :defaultContent="pageData.value"></ckEditorMain>
          </b-col>
          <b-col class="my-2" cols="6">
            <b-button type="button" class="btn w-25" variant="primary" @click="updateData">Submit</b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";
import {
  BAvatar, BBadge,
  BButton,
  BCard,
  BCol, BDropdown, BDropdownItem,
  BFormCheckbox,

  BFormFile,
  BFormGroup,
  BCarousel,
  BCarouselSlide,
  BFormInput, BFormSelect, BInputGroup, BLink,
  BMedia, BModal, BOverlay, BPagination,
  BRow,
  BTable
} from "bootstrap-vue";
import vSelect from "vue-select";
import Helper from "@/libs/Helper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ckEditorMain from "@/views/components/ckEditorMain";
import {DynamicPagesGetByType, UpdateDynamicPages} from "@/libs/Api/DynamicPages";
import LanguagePicker from "@/views/components/utils/LanguagePicker.vue";

export default {
  components: {
    LanguagePicker,
    ckEditorMain,
    AppCollapse,
    AppCollapseItem,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BCarousel,
    BCarouselSlide,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
  },
  name: "AboutUs",
  title: "About us",
  data() {
    return {
      showOverlay: false,
      rawHTML: '',
      languageId:1,
      pageData:null,
      // pageData: {
      //   type:3,
      //   value : null
      // },
    }
  },
  watch: {
    languageId: {
      handler(val) {
        this.getPageData();
      },
    },
  },
  async created() {
    await this.getPageData()
  },
  methods: {
    setEditorContent(content){
      this.pageData.value = content;
    },
    async getPageData() {
      let _this = this;
      _this.showOverlay = true;

      let dynamicPagesGetBy = new DynamicPagesGetByType(_this);
      dynamicPagesGetBy.setParams({
        type: 3,
        LanguageId:_this.languageId
      })
      await dynamicPagesGetBy.fetch(function (content) {
        _this.showOverlay = false;
        _this.pageData = content.data;
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    async updateData() {
      let _this = this;
      _this.showOverlay = true;

      let updateDynamicPages = new UpdateDynamicPages(_this);
      updateDynamicPages.setParams(_this.pageData);
      await updateDynamicPages.fetch(function (content) {
        _this.showOverlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            icon: 'CheckIcon',
            variant: 'success',
            text: `Operation successful`,
          },
        })
        _this.getPageData();
      }, function (error) {
        console.log(error);
        _this.showOverlay = false;
      })
    },
    setLanguage(language) {
      this.languageId = language.languageId;
    },
  }
}
</script>

<style scoped>

</style>
